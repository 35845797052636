import React from "react";
import {Modal} from "react-bootstrap";
import { Link } from 'react-router-dom'
import {Event} from "../../../../Helper";

export default class Help extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        };
    }

    componentDidMount() {
        Event.on('show_help', () => {
            this.setState({ show: true, effect: 'pulse' });
        })
    }

    showPrivacy = (e) =>{
        e.preventDefault();
        this.setState({ show: true, effect: 'pulse' });
    };

    closePrivacy = () =>{
        this.setState({ show: false, effect: 'zoomOut'});
    };

    render() {
        return(
            <>
               <Link className="text-left" to={'#'} onClick={this.showPrivacy}>
                    <i className="mdi mdi-help-circle-outline align-middle mr-1"></i>
                    <span className={'op-8 how-to-play'}>
                        How to play
                    </span>
                </Link>
                <Modal
                    size="lg"
                    centered={true}
                    backdrop={'static'}
                    show={this.state.show}
                    onHide={this.closePrivacy}
                    aria-labelledby="general-lg-modal"
                    className={"animated " + this.state.effect}
                >
                    <Modal.Header className={'font-light'}>
                       Help
                        <button type="button" className="close p-2" onClick={this.closePrivacy}>
                            <i className={'mdi mdi-close'}/>
                        </button>
                    </Modal.Header>
                    <Modal.Body className={'modal-reader'}>
                                               

                    </Modal.Body>
                </Modal>
            </>
        );
    }
}
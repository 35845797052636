import React from 'react'
import UserHeader from '../Parts/Header/Header-User';
import {Event, decode, encode, wait, isMobile, SITE, sendNotfication} from "../../../Helper";

import storage from "../../../Storage";


class ResponsibleGambling extends React.Component {
    _Mounted = false;
    constructor(props){
        super(props);
        this.state = {
            mobile:false,
            isLogged: false,
            token: false,
            userid: storage.getKey("uid"),
            header: <UserHeader t={this.props.t} location={this.props.location} />,
            auth: false
        }
        this.handleResize = this.handleResize.bind(this);
    }
    componentWillMount() {
        wait(500).then(() => {
            this._Mounted = true;
        })
    }

    componentDidMount() {
        wait(1700).then(() => {
            this.setState({ loading: false });
        })

    }


    getWidth(){
        return document.documentElement.clientWidth || document.body.clientWidth;
    }
    handleResize(){
        if(this.getWidth() < 1540)
        {
            this.setState({ col: 'col-xl-12'});
            Event.emit('hide_games');
        }
        else {
            this.setState({ col: 'col-xl-9'});
            Event.emit('show_min_games');
        }

        if(isMobile()){

            this.setState({ mobile: true });
        }
        else {
            this.setState({ mobile: false });
        }

        if(isMobile()){
            this.setState({ padding: 'p-0', ovh: 'ovh' });
        }
    }
    render(){
        let { header } = this.state;




        return(
            <>
                <div className="page-wrapper">

                    <div className="page-content container py-1 px-1" id={'page-content'}>
                        {header}
                        <section className="container">
                            <h1>
                                Responsible Gambling
                            </h1>

                            <p>
                                Mo2Bet's vision is to create a safe and conducive environment where players can engage
                                in betting and gambling activities while also enjoying their favourite sporting events.
                                Mo2Bet further urges its customers to treat betting and related gambling activities as
                                an enjoyable and fun activity and not as a source of livelihood.
                            </p>

                            <p>
                                What are the signs of irresponsible/uncontrolled gambling?<br/>
                                Betting with more money than you can afford to lose.<br/>
                                Chasing your losses. This simply means placing subsequent bets with the sole intention
                                of recovering money lost on previous bets.<br/>
                                Treating gambling activities as a source of income.<br/>
                                Spending a lot of time on betting sites making your predictions.<br/>
                                Betting when one is not in their normal psychological state.<br/>
                                Sacrificing time with friends, family or colleagues in order to engage in betting
                                activities.<br/>
                                Prioritizing gambling over other outside activities and engagements.<br/>
                            </p>
                            <p>
                                <strong>How can I become a responsible gambler?</strong>
                            </p>
                            <p>
                                To become a disciplined and responsible gambler, Mo2Bet urges you to do, among other
                                tips you may find elsewhere, the following;
                                Always bet with money you can afford to lose.<br/>

                                Do not chase your losses.<br/>

                                Remember that betting, as all other gambling activities, is a fun and enjoyable activity
                                and not a means by which to earn a living.<br/>

                                Spend as little time as possible on betting sites. Taking breaks from time to time is
                                highly recommended.<br/>

                                Do not engage in betting activities while overly excited, angry, sad, depressed etc. In
                                addition, do not place your bets while under the impairment of alcohol and drugs.<br/>

                                Be open to your trusted friends and family about your betting activities and they will
                                know if you need help.<br/>

                                Take time off of gambling. Venture outside and engage in essential socioeconomic
                                activities.<br/>
                            </p>
                            <p>
                                Mo2Bet has also taken great caution and measures to ensure that only individuals of
                                eighteen years and above can engage in the betting and gambling services offered through
                                their platform;<br/>
                                You must tick the box indicating you are of eighteen years and above at the time of
                                registration.<br/>
                                Adults are encouraged to protect their devices with passwords so as to prevent exposure
                                of betting activities to minors.<br/>
                                Adults are also encouraged to make use of website filtering and general blocking
                                softwares such as Net Nanny to limit exposure of potentially harmful web content
                                (gambling included) to minors.<br/>
                            </p>
                        </section>
                    </div>
                </div>
            </>
        );
    }
}


export default ResponsibleGambling;



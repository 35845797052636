const coins = [
    {
        id: 1,
        name: "KES",
        preffix: "kes",
        image: "kes.png",
        network: 'btc',
        min: "0.00008000",
        active: true
    },
];

export default coins;
import React from 'react'
import { Dropdown } from "react-bootstrap";
import Login from "../../Auth/Login";
import Chat from "./../Chat";
import Logo from "./../Logo";
import Register from "../../Auth/Register";
import {Event, BRAND} from "../../../../Helper";

import Wallet from "../../../Components/User/Wallet";


var show = true;

class Header extends React.Component {
    collapse = () =>{
        show = !show;
        Event.emit('toggle_sidebar', show);
    }
    render() {
        const { t } = this.props;
        return (
                        <div className="d-flex justify-content-between" id={'topbar2'}>
                            <Logo brand={BRAND} />  
                            <div>
                            <Login t={t} /><Register t={t} />
                            </div>
                            
                           
                           
                            
                        </div>
                        
        );
    }
}

export default Header;
import React from "react";
import {Link} from "react-router-dom";
import {BRAND} from "../../../../Helper";

import Help from '../Sidebar/Help';
class Favicon extends React.Component {
    constructor(props){
        super(props);
    }
    render() {
        const { t } = this.props;
        return(
            <>
                <div className="">
                    <Link to="/" className="logo">
                            <span>
                                <img src="/favicon.png?v=2" className="logo-sm" alt="Logo" />
                                
                            </span>
                            </Link>
                            {/* <br/>
                            <div className="t-row-wrap">
                            <Help t={t}/>   
                            <span className="fontt-10">
                                <a href="tel:0703637127">
                                <i className="mdi mdi-phone align-middle mr-1 ml-2"></i>
                                <span className={'op-8 how-to-play'}>
                                    0703 637 127
                                </span>
                                </a>
                            </span>
                            </div> */}
                            
                    
                </div>
            </>
        );
    }
}

export default Favicon;
/**
 * Socket Constant
 */

const values = {
    RPS: '651cb023e8a7c22b217106f3175522d3dk4v6',
    RPS_DISSCONECT: '7c3116ff7b0ec29ef8b0e56c71a080b3dk4v6',
    ROOM: 'fef2576d57dbde017a3a8e7df699ef6ddk4v6',
    CREDIT: '660cb6fe7737d7b70e7a07b706b93f70dk4v6',
    MY_BETS: 'fd2a0537bcdae1736f552707b3bd3156dk4v6',
    MY_HISTORY: 'fd2a0537bcdae1736f552707b3bd3157dk4v6',
    BANKROLL: '0c30c5a602062107a5d356d0eb1ebb8edk4v6',
    LAST_BETS_BY_GAME: 'b87a2e8036f0617125ffb69dd5673d7bdk4v6',
    LAST_BETS: '62f8c260fbce6de8e5ed19767977cc1edk4v6',
    TOP_WINNERS: 'b7cafd57089c07ade71b7776085660a0dk4v6',
    GET_ADDRESS: '396bbdcf7c16c3f3795d932b698ef78fdk4v6',
    CREDIT_COIN: 'e70b7663b91b67a7f7e027c00f5a30e2dk4v6',
    USER_INFO: '18566cda79f670c2098360799275aa31dk4v6',
    USER_CHART: '1cf37d076d187195c2d7d5e3678dfe0bdk4v6',
    GAME_DETAILS: '657cdcaf1b9072c7d708bb3766bd3915dk4v6',
    NOTIFICATION: 'f37bd2f66651e7d76f6d38770f2bc5dddk4v6',
    UPDATE_PAYMENT_STATUS: '23723ece8c3db7267fcaa661ec6a7f72dk4v6',
    DEPOSIT_HISTORY: 'cccff8ec16dfd682555f7ef1566c7757dk4v6',
    MESSAGES: 'de70938879b75d3db63bba721c93e018dk4v6',
    ADD_MESSAGES: '292d72d37f7e189059f7f998737de9bbdk4v6',
    SUBMIT_NEW_WITHDRAWL: '7c0b37955cf21c7f2f3773c1268edc08dk4v6',
    ADD_CHAT: '1e6ccf0ddced017179b173e5cc78beeadk4v6',
    CHATS: '7a7fe97bbc5ff21a561b79986db975c5dk4v6',
    ADD_FRIEND: '265ea6ce905188a0751e8f0273d30bb7dk4v6',
    SEND_TIP: '573a867973fa586555cab080e7d837addk4v6',
    EDIT_ACCOUNT: 'ca6e08ddde39ee9f965270b7d8175d17dk4v6',
    EDIT_PASSWORD: 'ed7feda03376fd39087183552f093e6adk4v6',
    WALLET_HISTORY: 'c23c59dd3258d3a53d7132652f8bf98adk4v6',
    MY_FRIENDS: '1e73d7d857e371f00a56105a7a38a576dk4v6',
    SAVE_ACCOUNT: '70a765ca577e8cc77d3e27f70e56b237dk4v6',
    SAVE_ACCOUNT_PASSWORD: 'bd3d5bf93eb508dc9282a1077a16a773dk4v6',
    SAVE_AVATAR: 'd0779dab750dc765ddcf06b190ad82bfdk4v6',
    ONLINE: '7f76165777d11ee5836777d85df2cdabdk4v6',
    GET_UID: '002b67aa7d872615cc6ef9ffa78c766ddk4v6',
    AUTH: 'fa53b91ccc1b78668d5af58e1ed3a785dk4v6',
    ADMIN_ADD_CHAT: 'b638e57f1f2bb7979c9a7796d6be671ddk4v6',
    UPDATE_CREDIT: '80d8b773e76b21777faaccfbd3c2a687dk4v6',
    ADD_BET: '86751663a7022702d9630a115515da7bdk4v6',
    SUPPORT_EMAIL: '92beb160c15977c905cb7b72138e26c0dk4v6',
    LOGIN_USER: 'faf9ba208ad90e7313b6ffafde53b801dk4v6',
    LOGIN_USER_GOOGLE: '383f7bf0257c3ef6cab20278dd1579bedk4v6',
    REGISTER_USER: '0a2637735ee07dd5f0e5eba7b9ca1ce7dk4v6',
    RESET_PASSWORD: '62a0b91a9b98a7ec19f27e72c13de207dk4v6',
    RESET_CODE: '62a0b91a9b98a7ec19f27e72c13gx890dk4v6',
    LOGOUT_USER: '1f7009c5312bab76e660578ecbe08350dk4v6',
    CREDIT_ERROR: '1fdf15e7dcba3211ebe22e5fdbcec79fdk4v6',
    RAIN: '23678db5efde9ab76bce8c23a6d91b50dk4v6',
    MY_AFF: '158674db5efde9ab76bce8c23a6d91b50dk4v6',

    HISTORY_CRASH: 'dcaa9fd1f23aaf0c23f570becf35b72fdk4v6',
    STATUS_CRASH: 'dcaa9fd7f23aaf0c29f570becf35b76fdk4v6',
    PLAYERS_CRASH: '0fd0a8ecb587292055e1c775d6c39a7edk4v6',
    PLAY_CRASH: '05131bff83db9a797b5e9793cfa3bcf6dk4v6',
    FINISH_CRASH: '97c73db9a306213ac2b5c3bdecd20e75dk4v6',
    WAITING_CRASH: 'be91b2a797f2961c59b2780d2cd72e12dk4v6',
    STARTED_CRASH: 'e112267b9590259c29b9301fa39c1f3ddk4v6',
    BUSTED_CRASH: 'a8f6d02877d198b08b7c7f2a1af69d06dk4v6',
    ERROR_CRASH: 'd9fe15b677f93abbe07076807291e9c6dk4v6',
    STK_DEPOSIT: '660cb6fe7737d7b70e7a07ty90xci65rdk4v6',
    STP_GAMES: '1586bce8c23a6d91674db5efde9ab7b50dk4v6',
    STP_GAME: '1586d91674db5efde9ab7b50dk4v6bce8c23a6',
};

export default values;